<!--  -->
<template>
  <div class="">
    <a-row :gutter="16" style="height: 100%">
      <a-col class="gutter-row" :span="4" style="height: 100%">
        <silder-menu-list class="cursor"
          @changeTableName="changeTableName"
          :menuListData="menuListData"
          :searchTitle="searchTitle"
        ></silder-menu-list>
      </a-col>
      <a-col
        class="gutter-row"
        :span="20"
        style="height: 100%; border-left: 1px solid #d8d8d8"
      >
        <!-- 特殊妇女 -->
        <psychiatricPatients
          v-if="tableName === '精神病患者'"
        ></psychiatricPatients>
        <emancipist v-if="tableName === '刑满释放人员'"></emancipist>
        <communityCorrection
          v-if="tableName === '社区矫正人员'"
        ></communityCorrection>
        <drugAddicts v-if="tableName === '吸毒人员'"></drugAddicts>
        <evilRelatedPersonnel
          v-if="tableName === '涉邪人员'"
        ></evilRelatedPersonnel>
        <badBehavior v-if="tableName === '特殊未成年人'"></badBehavior>
        <keynote v-if="tableName === '重点维稳信访人员'"></keynote>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import api from "./api";
//精神病患者
import psychiatricPatients from "./psychiatricPatients/index.vue";
// 刑满释放人员
import emancipist from "./emancipist/index.vue";
// 社区矫正人员
import communityCorrection from "./communityCorrection/index.vue";
// 吸毒人员
import drugAddicts from "./drugAddicts/index.vue";
// 涉邪人员
import evilRelatedPersonnel from "./evilRelatedPersonnel/index.vue";
// 特殊未成年人
import badBehavior from "./badBehavior/index.vue";
// 重点维稳信访人员
import keynote from "./keynote/index.vue";

import SilderMenuList from "./silderMenuList.vue";
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';
const menuListData = [
  "精神病患者",
  "刑满释放人员",
  "社区矫正人员",
  "吸毒人员",
  "涉邪人员",
  "特殊未成年人",
  "重点维稳信访人员",
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SilderMenuList,
    psychiatricPatients,
    emancipist,
    communityCorrection,
    drugAddicts,
    evilRelatedPersonnel,
    badBehavior,
    keynote,
  },
  data() {
    //这里存放数据
    return {
      api,
      menuListData,
      tableName: menuListData[0],
      searchTitle: "重点人群分类",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    changeTableName(item) {
      console.log(item);
      this.tableName = item;
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.ant-list-item {
  justify-content: flex-start !important;
  margin: 2% 5%;
  background-color: #fff;
  border-radius: 4px;
}
.cursor{
  cursor: pointer;
}
</style>